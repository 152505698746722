import React, { Component } from 'react';
import LeftMenu from '../include/LeftMenu';
import AutoHeight from 'react-auto-height';
import Header from '../include/Header';
import IBSheet from '../IBSheet';

import ReturnIcon from '@material-ui/icons/LocalShipping';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/NoteAdd';
import ExcelDown from '../include/ExcelDown';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import { getCustAssetStatusList } from '../../service/rental/RentalDeadlineStatusService';
import { getSpotList } from '../../service/members/MembersService';
import { dateToFormat, errorMsgCheck, resultToIbSheet, InsertComma, SheetParamData, SheetParamData3 } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, LargeInputNoPopupStore, AssetHistoryPopStore, AssetReturnPopStore } = CommonStore;

var result = {
    //sheet데이터 
    data: [],
}

class RentalDeadlineStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/* 검색조건 */
			sCustNo: '',
			sCustNm: '',
			sDate: moment(),
			sUserNm: '',
			sAsstStatus: '',
			sAsstNo: '',

			/* 상세정보 */
			asstSeq: '',
			hisParamAsstNo: '',
			asstNo: '',
			preAsstNo: '',
			contNo: '',
			used: '',
			realUseSpot: '',
			etc: '',
			itemGrp: '',
			model: '',
			fromDate: '',
			toDate: '',
			monthPrc: '',
			totAsstCnt: '0',
			totMonthPrc: '0',
			excelList: [], //엑셀다운로드용 리스트
		};

		this.sheetData = {
			id: 'rentalDeadlineStatusSheet', // sheet id
			initData: 'rental/Init-RentalDeadlineStatus', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '400px', // sheet height
		};
	}

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	getAssetList = () => {
		ProgressStore.activeProgress();

		let param = {
			custNo: this.state.sCustNo,
			asstStatus: this.state.sAsstStatus,
			asstNo: this.state.sAsstNo,
			userNm: this.state.sUserNm,
			sDate: dateToFormat(this.state.sDate).substring(0, 7),
		};

		getSpotList(param)
			.then((r) => {
				window.rentalDeadlineStatusSheetCombo(r);
			})
			.then(() => {
				getCustAssetStatusList(param)
					.then((r) => {
						window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));

						let totmonthPrc = 0;
						for (var i in r) {
							totmonthPrc = totmonthPrc + r[i].dcMonthPrc;
						}

						this.setState({
							totAsstCnt: r.length,
							totMonthPrc: InsertComma(totmonthPrc),
						});
						this.makeExcelData(r);

						ProgressStore.deactiveProgress();
					})
					.catch((err) => {
						errorMsgCheck(err, ProgressStore.deactiveProgress());
					});
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	};

	getAssetDetail = () => {
		if (window[this.sheetData.id].LastRow() === 0) return;

		let data = window.getAssetSelectData();
		if (!data) return;
        debugger;
		this.setState({
			asstSeq: data.asstSeq,
			asstNo: data.asstNo,
			preAsstNo: data.preAsstNo,
			contNo: data.contNo,
		});
	};

	handleSearchBtn = () => {
		if (this.state.sCustNo === '') {
			ModalStore.openModal('고객을 반드시 입력해 주세요.');
			return;
		}
		this.setEmptyField(); //초기화
		this.getAssetList();
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.No = idx + 1;
			tmp.자산번호 = item.asstNo;
			tmp.자산상태 = item.asstStatus;
			tmp.지점코드 = item.spotId;
			tmp.지점명 = item.spotNm;
			tmp.부서코드 = item.userDeptId;
			tmp.부서명 = item.userDeptName;
            tmp.사용자명 = item.userName;
            tmp.사용자사번 = item.userEmpno;
            tmp.실사용장소 = item.realUseSpot;
            tmp.제품분류 = item.itemGrp;
            tmp.모델명 = item.model;
			tmp.렌탈시작일 = dateToFormat(item.fromDate);
			tmp.렌탈종료일 = dateToFormat(item.toDate);
			tmp.월렌탈료 = item.dcMonthPrc;
			tmpList.push(tmp);
		});
		this.setState({ excelList: tmpList });
	};

	saveGridInfo = () => {

		let sheet = window[this.sheetData.id];
		let sheetParam = SheetParamData3(sheet, null, false);
		if (sheetParam.Message == 'KeyFieldError') {
			return;
		}
		ProgressStore.activeProgress();

		saveCustAssetStatusManagerList(sheetParam)
			.then((r) => {
				ModalStore.openModal('저장되었습니다.', () => {
					this.getAssetList();
					this.setEmptyField();
				});

			})
			.catch((err) => {
				errorMsgCheck(err, undefined, () => ProgressStore.deactiveProgress());
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});

	};

	returnAsset = () => {
		if (!this.state.asstNo) {
			ModalStore.openModal('반납할 자산을 선택해 주세요.');
			return;
		}
		const param = {
			asstSeq: this.state.asstSeq,
			asstNo: this.state.asstNo,
			contNo: this.state.contNo,
			custNo: this.state.sCustNo,
		};
		AssetReturnPopStore.openPopup(param, null, this.modalClose);
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustClose);
	};

	handleCustClose = (item) => {
		if (item === undefined) return;
		this.setState({
			sCustNo: item.custNo,
			sCustNm: item.custNm,
		});
	};

	handleTextArray = (text) => {
		if (text === '') return;
		this.setState({
			sAsstNo: text,
		});
	};

	modalClose = (reSearch) => {
		if (reSearch) {
			//재조회
			this.handleSearchBtn();
		}
	};

	assetHistoryPop = (type) => {
		let paramAsstNo = '';
		if (type === 'N') {
			if (!this.state.asstNo) {
				ModalStore.openModal('자산을 선택해 주세요.');
				return;
			}
			paramAsstNo = this.state.asstNo;
		} else if (type === 'P') {
			if (!this.state.preAsstNo) {
				ModalStore.openModal('이전 자산번호가 없습니다.');
				return;
			}
			paramAsstNo = this.state.preAsstNo;
		}

		AssetHistoryPopStore.openPopup(this.state.sCustNo, paramAsstNo);
	};

	setEmptyField = () => {
		this.setState({
			asstSeq: '',
			asstNo: '',
			preAsstNo: '',
		});
	};

	dateHandler = (date) => {
		this.setState({ sDate: date });
	};

	render() {
		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
                        <div className="leftMenu">
								<LeftMenu />
                        </div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>렌탈마감현황</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.handleSearchBtn}>
										<span>조회</span>
									</a>
									<ExcelDown modalOpen={ModalStore.openModal} data={this.state.excelList} text="엑셀" filename={this.state.sCustNm + '_렌탈마감현황.csv'} />
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col style={{ width: '280px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '120px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '280px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 220 }} value={this.state.sCustNm} />

												<IconButton onClick={this.customerSearch} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>자산상태</th>
											<td>
												<select style={{ width: 120 }} onChange={(e) => this.setState({ sAsstStatus: e.target.value })}>
													<option value="1">사용</option>
													<option value="2">변경</option>
													<option value="3">반납</option>
													<option value="" selected>
														전체
													</option>
												</select>
											</td>
											<th>기준년월</th>
											<td>
												<DatePicker
													customInput={<input type="text" style={{ width: 80 }} />}
													dateFormat="YYYY-MM"
													onChange={(date) => this.dateHandler(date, 's')}
													selected={this.state.sDate}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
										</tr>
										<tr>
											<th>사용자명</th>
											<td>
												<input
													type="text"
													style={{ marginRight: 10, width: 160 }}
													value={this.state.sUserNm}
													onChange={(e) => this.setState({ sUserNm: e.target.value })}
												/>
											</td>
											<th>자산번호</th>
											<td colSpan="3">
												<input
													type="text"
													style={{ marginRight: 10, width: 120 }}
													value={this.state.sAsstNo}
													onChange={(e) => this.setState({ sAsstNo: e.target.value })}
												/>
												<Button
													color="primary"
													variant="outlined"
													size="small"
													style={{ marginLeft: 5, alignItems: 'initial' }}
													onClick={() => LargeInputNoPopupStore.openPopup(this.handleTextArray)}
												>
													<AddIcon style={{ fontSize: 20, marginRight: 5 }} />
													대량입력
												</Button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="writeType03" onClick={this.getAssetDetail}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">자산리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div style={{ marginTop: 10 }}>
								<p className="headline">자산정보</p>
								<div className="writeType03_noborder">
									<table>
										<colgroup>
											<col style={{ width: 90 }} />
											<col style={{ width: 150 }} />
											<col style={{ width: 100 }} />
											<col style={{ width: 170 }} />
											<col style={{ width: 100 }} />
											<col />
										</colgroup>
										<tbody>
											<tr>
												<th>자산번호</th>
												<td>
													<input type="text" readOnly={true} style={{ width: 90 }} value={this.state.asstNo} />
													<IconButton onClick={() => this.assetHistoryPop('N')} color="secondary" className="iconButton">
														<SearchIcon fontSize="small" />
													</IconButton>
												</td>
                                                <th>이전자산번호</th>
                                                <td>
                                                    <input type="text" readOnly={true} style={{ width: 90 }} value={this.state.preAsstNo} />
                                                    <IconButton onClick={() => this.assetHistoryPop('P')} color="secondary" className="iconButton">
                                                        <SearchIcon fontSize="small" />
                                                    </IconButton>
                                                </td>
												<th>검색된자산수</th>
												<td>
													<input type="text" readOnly={true} value={this.state.totAsstCnt} />
												</td>
												<th>예상월렌탈료</th>
												<td>
													<input type="text" readOnly={true} value={this.state.totMonthPrc} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							{/*<div style={{ marginTop: 5, float: 'right' }}>
								<Button color="primary" variant="contained" size="small" style={{ alignItems: 'initial' }} onClick={this.checkGridSave}>
									<SaveIcon style={{ fontSize: 20, marginRight: 5 }} />
									저장
								</Button>
								 <Button color="secondary" variant="contained" size="small" style={{ marginLeft: 10, alignItems: 'initial' }} onClick={this.returnAsset}>
									<ReturnIcon style={{ fontSize: 20, marginRight: 5 }} />
									자산반납
								</Button>
							</div>
							*/}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(RentalDeadlineStatus);